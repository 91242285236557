import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { PoweredByAmwell } from '../../components/icons';
import BackgroundImage from '../../assets/img/background-blurred.png';
import { StateContext } from '../../lib/providers';
import './scss/Confirmation.scss';
import {
  ConfirmationSignIn,
  ConfirmationDetails,
} from '../../components/checkout/confirmation';
import { ViewLayout } from '../../components/layout';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default function Confirmation() {
  const state = useContext(StateContext);
  const [order, setOrder] = useState(null);
  const { user } = state || {};
  useEffect(() => {
    const orderObject = JSON.parse(localStorage.getItem('amwell-order'));
    setOrder(orderObject);
    console.log('orderObject', orderObject);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Amwell Private Practice | Order Confirmation</title>
      </Helmet>
      <ViewLayout>
        <div className="Confirmation">
          <img
            className="Confirmation-background"
            src={BackgroundImage}
            alt="order confirmation background"
          />
          <div className="Confirmation-container">
            <h1 className="Confirmation-heading">
              <Trans>Amwell Private Practice Plan Confirmation</Trans>
            </h1>

            <OrderConfirmation order={order} />
            {user ? null : <ConfirmationSignIn />}

            <p className="Confirmation-copyright">
              © 2020 American Well Corporation. All rights reserved.
            </p>
          </div>
          <div className="Confirmation-branding">
            <PoweredByAmwell />
          </div>
        </div>
      </ViewLayout>
    </>
  );
}

const OrderConfirmation = ({ order }) =>
  order ? (
    <ConfirmationDetails order={order} />
  ) : (
    <UnavailableOrderConfirmation />
  );

const UnavailableOrderConfirmation = () => (
  <div>
    <p>The was an issue retrieving your order confirmation details.</p>
    <p>
      Please try signing in and viewing your order for the account dashboard.
    </p>
    <br />
  </div>
);
